
.dev {
    &.watermark {
        position: absolute;
        bottom: 0;
        left: 0;
        font-style: italic;
        color: #aaaaaa;
        font-size: 10px;
        cursor: pointer;
        background-color: fadeout(#ffff99, 50%);
    }
}
