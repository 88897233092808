@import "bootstrap/bootstrap";
@import "_development";
@import "../node_modules/@tt/ui/css/styles/icons";
@import "../node_modules/@tt/ui/css/styles/animations";

//Chrome adds an ugly focus border on some elements. Removing that.
a:focus, ul:focus {
    outline: none;
}

.navbar-fixed-top {

    .toolbar-icon {
        color: #555555;
        line-height: 30px;
        padding-right: 10px;
    }
}

.ttui-icon-20 {
    width: 20px;
    height: 20px;
}

//this is for a div that marks the area where the titlebar will show.
// it makes the effect of a slow to load titlebar less jarring.
#titlebar-placeholder {
    background-color: #f3f3f3;
    width: 100%;
    height: 30px;
    position: fixed;
    top: 0;
    z-index: 1;
}

body {
    ._preLoadFont {
        color: rgba(0, 0, 0, 0);
    }
    & > .page-content {
        // the page main content starts hidden and is revealed once the user gets authenticated
        display: none;
    }

    .init-banner {
        .loading-gif-banner {
            height: 32px;
            width: 32px;
            margin-bottom: 5px;
        }
    }

    .login-guard-confirm-modal {
        display: flex !important;
        overflow: auto;
        position: fixed;
        margin-top: 50px;
        left: 50%;
        transform: translate(-50%);
        visibility: visible !important;
        min-height: 206px;
        min-width: 500px;
        max-width: max-content;

        .btn-login-guard-confirm {
            background-color: #4F535B;
        }
    }

    .popover.bottom.in.login-guard-modal{
        visibility: hidden;
    }
    .init-banner.has-detail.show-help-text.login-guard-modal{
        top: 10%;
        z-index: @zindex-over-widgets ;
    }

    .centered-banner {
        border-radius: 4px;
        border: solid 1px #cccccc;
        background-color: #eeeeee;
        position: absolute;
        z-index: 10000;
        padding: 20px;
        margin: auto;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
    }

    &.workspace {
        & > .init-banner {
            width: 370px;
        }
    }

    & > .init-banner,
    & > .custom-init-banner {
        & > svg {
            fill: #999999;
            position: relative;
            top: -3px;
        }
        .centered-banner();
        padding: 16px;
        font-size: 20px;
        font-weight: bold;
        color: #999999;
        width: 220px;
        height: 63px;

        &.has-detail {
            height: 300px;
            .startup-item-container {
                display: block;
            }

            &.show-help-text {
                height: 422px;
                &.tradestation {
                    height: 427px;
                }
                &.coinflex {
                    height: 400px;
                }
                .help-text {
                    display: block;
                }
            }
        }

        .help-text {
            display: none;
            padding-top: 7px;
            font-size: 11px;
            color: #555555;
            font-weight: initial;
            padding-left: 3px;

            .trading-emergency {
                font-style: italic;
                margin-bottom: 0;
            }

            .toll-free-number {
                font-style: italic;
            }

            .refresh-workspace, 
            .refresh-workspace-no-ticket,
            .send-alert {
                cursor: pointer;
            }
        }

        .startup-item-container {
            display: none;
            font-weight: initial;
            color: #555555;
            background: #ffffff;
            height: 217px;
            margin-top: 15px;
            overflow-y: scroll;
            font-size: 12px;
            border: 1px #cccccc solid;
            .startup-item {
                padding-left: 10px;
                .display {
                    display: inline-block;
                    max-width: 90%;
                }
                .message-item {
                    padding: 0 10px 5px 10px;
                }
                .messages-icon {
                    float: right;
                    padding-right: 10px;
                    cursor: pointer;
                }
            }
        }
    }

    & > .page-load-error {
        // This shows if there's an error trying to load the (supposedly) authenticated user's data from the TTW server
        .centered-banner();
        display: none;
        color: #7d0308;
        border-color: #deaaad;
        background-color: #f6dbde;
        width: 450px;
        height: 63px;
        font-size: 16px;

        .message {
            text-align: center;
            white-space: pre-wrap;
            position: relative;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .detail {
            display: none;
            overflow-y: scroll;
            font-family: Consolas, Menlo, Monaco, monospace;
            white-space: pre-wrap;
            font-size: 12px;
            height: 112px;
            margin-top: 10px;
            background-color: #f1c9c9;
        }
    }
}

#ntw-file-download-helper {
    display: none;
}

input[type=checkbox] {
    -ms-transform: scale(0.75);
}

.noty_text .detail {
    font-size: 10px;
    font-style: italic;
}

#emptyWorkspaceMsg {
    display: none;
}
